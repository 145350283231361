import { DateTime } from 'luxon'
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Predictions, verticalBlockStyle } from 'shared/components/Predictions'
import { useInterval } from 'shared/hooks/useInterval'
import { fetch_ } from 'shared/utils/fetch'
import { timeout } from 'shared/utils/function/timeout'
import { ms, ms2sec, sec2ms } from 'shared/utils/time'
import {
  MAX_VOL,
  MIN_VOL,
  computeAdmissibleVolume,
} from 'shared/utils/web/audio'
import { onError } from 'shared/utils/web/error'
import { S3Sound, fetchData, formatPrefix } from 'shared/utils/web/fetchData'
import { defaultMetrics } from 'shared/utils/web/metrics'
import { LabelsDialog } from './LabelsDialog'
import { useS3Context } from './S3'
import { TimeMarks, formatTime } from './TimeMarks'
import { Button } from './components/Button'
import { usePredictions } from './hooks/usePredictions'

const BUCKET_DURATION = ms(1, 'minute')
const SOUND_DURATION = ms(9.6, 'seconds')

const INITIAL_DURATION = ms(10, 'minutes')
const DURATION_DELTA = ms(5, 'minutes')
const MAX_DURATION = ms(120, 'minutes')

type Props = {
  sequenceKey: string
  serial: string
  instants: string[]
  sequenceIndex: number
}

// 400 aws errors
// hover highlights class -> prevented by none pointer events
// up down for volume, auto

type Prefix = string
// undefined = prefix loading is not yet started
// null = prefix loading is in progress
type PrefixDataMap = Record<Prefix, S3Sound[] | undefined | null>

export const TimeLine = ({
  sequenceKey,
  instants,
  sequenceIndex,
  serial,
}: Props) => {
  const { s3Client, getS3Url } = useS3Context()

  const instantsTs = useMemo(
    () => instants.map((instant) => new Date(instant).valueOf()),
    [instants],
  )

  const refInstant = useMemo(
    () =>
      new Date(sequenceKey.substring(sequenceKey.indexOf('-') + 1)).valueOf(),
    [sequenceKey],
  )

  const [start, setStart] = useState(
    DateTime.fromMillis((instantsTs.at(0) ?? 0) - INITIAL_DURATION)
      .startOf('minute') // since fetchData has a minute resolution
      .valueOf(),
  )

  const [end, setEnd] = useState(instantsTs.at(sequenceIndex) ?? 0)

  const { predictions, extendInterval } = usePredictions(
    Object.keys(defaultMetrics),
    serial,
  )

  // Extend end when sequenceIndex changes
  useEffect(() => {
    setEnd(instantsTs.at(sequenceIndex) ?? 0)
  }, [instantsTs, sequenceIndex])

  // Compute new predictions as interval evolves
  useEffect(() => {
    extendInterval(start, end)
  }, [extendInterval, start, end])

  const bucketPrefixes: string[] = useMemo(() => {
    let date = DateTime.fromMillis(start).startOf('minute')
    const prefixes = []
    while (date.valueOf() <= end) {
      const prefix = date.toISO().slice(0, 16) // round to minute
      prefixes.push(prefix)
      date = date.plus({ minute: 1 })
    }
    return prefixes
  }, [start, end])

  const [prefixDataMap, setPrefixDataMap] = useState<PrefixDataMap>(() =>
    bucketPrefixes.reduce(
      (acc, prefix) => ({ ...acc, [prefix]: undefined }),
      {},
    ),
  )

  // Load all files from s3 for this prefix
  const loadBucket = useCallback(
    async (prefix: string) => {
      try {
        if (s3Client) {
          const formattedPrefix = formatPrefix(serial, prefix)
          const data = await fetchData(s3Client, formattedPrefix)

          setPrefixDataMap((prevPrefixDataMap) => ({
            ...prevPrefixDataMap,
            [prefix]: data,
          }))
        }
      } catch {
        onError
      }
    },
    [s3Client, serial],
  )

  const trackRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  const [soundTexts, setSoundTexts] = useState<
    Record<string, string | undefined | null>
  >({})
  const [isPlaying, setIsPlaying] = useState(false)
  const [percent, setPercent] = useState(0)
  const [playbackRate, setPlaybackRate] = useState(1)

  const [currentSoundKey, setCurrentSoundKey] = useState<string>()
  const soundURLs = useRef<Record<string, string>>({})
  const audioRefs = useRef<Record<string, React.RefObject<HTMLAudioElement>>>(
    {},
  )
  const [displayLabelDialog, setDisplayLabelDialog] = useState(false)
  const [contrast, setContrast] = useState(50)
  const [scale, setScale] = useState(1)
  const [admissibleVolume, setAdmissibleVolume] = useState(MAX_VOL)
  const [volume, setVolume] = useState(MAX_VOL)
  const [autoVolumeAdjust, setAutoVolumeAdjust] = useState(true)

  // Flatten all prefixes into a single array of sounds
  const data = useMemo(
    () =>
      bucketPrefixes.reduce<S3Sound[]>((acc, prefix) => {
        acc.push(...(prefixDataMap[prefix] ?? []))
        return acc
      }, []),
    [bucketPrefixes, prefixDataMap],
  )

  // Compute or update soundURLs
  useEffect(() => {
    data.forEach(async ({ soundKey }) => {
      if (soundURLs.current[soundKey] === undefined) {
        soundURLs.current[soundKey] = await getS3Url(soundKey)
      }
    })
  }, [data, getS3Url])

  // Compute or update audio refs
  useEffect(() => {
    data.forEach(({ soundKey }) => {
      if (audioRefs.current[soundKey] === undefined)
        audioRefs.current[soundKey] = React.createRef<HTMLAudioElement>()
    })
  }, [data])

  const lastDataIndex = useCallback(() => {
    let index = data.length - 1
    while (index > 0 && data[index].endTimestamp - SOUND_DURATION > end) index--
    return index
  }, [data, end])

  useEffect(() => {
    if (data.length === 0) return // Avoid useless computation

    if (
      Object.values(prefixDataMap).every(
        (soundData) => soundData !== null && soundData !== undefined,
      )
    ) {
      // Done loading all bucket prefixes
      const index = lastDataIndex()

      // Set sound index before instant when all prefix buckets are loaded, only on first instant
      if (currentSoundKey === undefined)
        setCurrentSoundKey(data[index].soundKey)

      // Make sure last visible sound is not clipped
      if (
        data[index].endTimestamp - SOUND_DURATION <= end &&
        data[index].endTimestamp > end
      ) {
        setEnd(data[index].endTimestamp)
      }
    }
  }, [prefixDataMap, data, end, lastDataIndex, currentSoundKey])

  const currentAudioElement =
    currentSoundKey && audioRefs.current
      ? audioRefs.current[currentSoundKey]?.current ?? null
      : null

  // Set currentAudioElement volume when volume state is updated
  useEffect(() => {
    if (currentAudioElement === null) return
    currentAudioElement.volume = volume
  }, [volume, currentAudioElement])

  const setTimeStamp = useCallback(
    (timestamp: number) => {
      let index
      let localTimestamp = 0
      for (index = 0; index < data.length; index++) {
        const { endTimestamp } = data[index]
        const startTimestamp = endTimestamp - SOUND_DURATION
        if (timestamp <= endTimestamp && timestamp >= startTimestamp) {
          localTimestamp = ms2sec(timestamp - startTimestamp)
          break
        }
        if (startTimestamp > timestamp) {
          break
        }
      }

      if (index < data.length) {
        const newSoundKey = data[index].soundKey
        if (newSoundKey !== currentSoundKey) {
          currentAudioElement?.pause()
          setCurrentSoundKey(newSoundKey)
        }

        const newAudioElement = audioRefs.current[newSoundKey].current
        if (newAudioElement === null) return

        newAudioElement.currentTime = localTimestamp
        if (isPlaying) newAudioElement.play()
      }
    },
    [data, currentSoundKey, currentAudioElement, audioRefs, isPlaying],
  )

  // Load sound texts
  useEffect(() => {
    for (const { soundKey, textKey } of data) {
      if (textKey !== undefined && soundTexts[soundKey] === undefined) {
        setSoundTexts((soundTexts) => ({ ...soundTexts, [soundKey]: null }))
        const fetchText = async () => {
          const textUrl = await getS3Url(textKey)
          const response = await fetch_(textUrl)
          const text = await response.text()
          setSoundTexts((soundTexts) => ({ ...soundTexts, [soundKey]: text }))
        }
        fetchText()
      }
    }
  }, [data, getS3Url, soundTexts])

  // Helper function for prev/next
  const startPlayAtIndex = useCallback(
    (index: number) => {
      currentAudioElement?.pause()
      const soundKey = data[index].soundKey
      setCurrentSoundKey(soundKey)
      const audioElement = audioRefs.current[soundKey].current
      if (audioElement === null) return
      audioElement.currentTime = 0
      if (isPlaying) audioElement.play()
    },
    [currentAudioElement, data, isPlaying],
  )

  const getCurrentSoundIndex = useCallback(
    () => data.findIndex((soundData) => soundData.soundKey === currentSoundKey),
    [data, currentSoundKey],
  )
  const previousSound = useCallback(() => {
    if (currentAudioElement === null) return
    const currentSoundIndex = getCurrentSoundIndex()

    if (sec2ms(currentAudioElement.currentTime) < 0.1 * SOUND_DURATION) {
      if (currentSoundIndex > 0) {
        startPlayAtIndex(currentSoundIndex - 1)
      }
    } else {
      currentAudioElement.currentTime = 0
    }
  }, [getCurrentSoundIndex, startPlayAtIndex, currentAudioElement])

  const nextSound = useCallback(() => {
    const currentSoundIndex = getCurrentSoundIndex()

    if (currentSoundIndex < lastDataIndex()) {
      startPlayAtIndex(currentSoundIndex + 1)
    }
  }, [getCurrentSoundIndex, lastDataIndex, startPlayAtIndex])

  // Handle sound player events,
  useEffect(() => {
    if (currentAudioElement === null) return

    // Automatically adjust volume
    const handleCanPlay = () => {
      if (currentSoundKey === undefined) return

      const AudioContext =
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        window.AudioContext || (window as any).webkitAudioContext

      const audioContext = new AudioContext()

      fetch_(soundURLs.current[currentSoundKey])
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          if (audioBuffer === undefined)
            throw Error('Unable to decode audio data')
          const newAdmissibleVolume = computeAdmissibleVolume(audioBuffer)
          setAdmissibleVolume(newAdmissibleVolume)
          if (autoVolumeAdjust) setVolume(newAdmissibleVolume)
        })
        .catch(onError)
    }
    currentAudioElement.addEventListener('canplay', handleCanPlay)

    // Play next at end
    currentAudioElement.addEventListener('ended', nextSound)

    // Handle errors
    currentAudioElement.addEventListener('error', onError)

    return () => {
      currentAudioElement.removeEventListener('canplay', handleCanPlay)
      currentAudioElement.removeEventListener('ended', nextSound)
      currentAudioElement.removeEventListener('error', onError)
    }
  }, [autoVolumeAdjust, currentAudioElement, currentSoundKey, nextSound])

  // Update percent while playing
  function updatePercent() {
    if (!currentAudioElement) {
      // Hacky, force a repaint to update currentAudioElement
      setPercent(0.001 * Math.random())
      return
    }

    const currentSoundIndex = getCurrentSoundIndex()

    const soundStartTimeStamp =
      data[currentSoundIndex].endTimestamp - SOUND_DURATION
    const localCurrentTimestamp = sec2ms(currentAudioElement.currentTime)
    const globalCurrentTimestamp = soundStartTimeStamp + localCurrentTimestamp

    // Pause when end is reached
    if (globalCurrentTimestamp >= end) currentAudioElement.pause()

    // Clamp at start
    if (globalCurrentTimestamp < start)
      currentAudioElement.currentTime = ms2sec(start - soundStartTimeStamp)

    setIsPlaying(!currentAudioElement.paused)

    setPercent(((globalCurrentTimestamp - start) / (end - start)) * 100)
  }

  useInterval(updatePercent, 250)

  const togglePlayPause = useCallback(() => {
    if (currentAudioElement === null) return

    if (currentAudioElement.paused) currentAudioElement.play()
    else currentAudioElement.pause()
  }, [currentAudioElement])

  const handleTrackClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (trackRef.current === null) return
      const trackRect = trackRef.current.getBoundingClientRect()
      const percent = (event.clientX - trackRect.left) / trackRect.width
      setTimeStamp(start + (end - start) * percent)
    },
    [start, end, setTimeStamp],
  )

  // Playback rate
  useEffect(() => {
    if (currentAudioElement === null) return
    currentAudioElement.playbackRate = playbackRate
  }, [playbackRate, currentAudioElement])

  const handlePlaybackRateClick = useCallback(() => {
    const playbackRates = [1, 1.25, 1.5, 2, 3]
    setPlaybackRate((prevPlaybackRate) => {
      return playbackRates[
        (playbackRates.indexOf(prevPlaybackRate) + 1) % playbackRates.length
      ]
    })
  }, [])

  function handleIncreaseDuration() {
    setStart((start) => Math.max(start - DURATION_DELTA, end - MAX_DURATION))
  }

  // Keyboard handlers
  useEffect(() => {
    if (currentAudioElement === null) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        e.preventDefault()
        togglePlayPause()
      } else if (e.key === 'ArrowLeft') {
        previousSound()
      } else if (e.key === 'ArrowRight') nextSound()
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [currentAudioElement, togglePlayPause, previousSound, nextSound])

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === '+') {
        setVolume((volume) => Math.min(1, Math.max(0, volume + 0.05)))
      } else if (e.key === '-') {
        setVolume((volume) => Math.min(1, Math.max(0, volume - 0.05)))
      }
    }

    window.addEventListener('keypress', handleKeyPress)
    return () => window.removeEventListener('keypress', handleKeyPress)
  })

  // Trigger a loadBucket when new buckets are added
  useEffect(() => {
    for (const prefix of bucketPrefixes) {
      if (prefixDataMap[prefix] === undefined) {
        setPrefixDataMap((prevPrefixDataMap) => ({
          ...prevPrefixDataMap,
          [prefix]: null,
        }))
        loadBucket(prefix)
      }
    }
  }, [bucketPrefixes, prefixDataMap, loadBucket])

  function closePanel<T>(event?: React.MouseEvent<T>) {
    event?.stopPropagation()
    setDisplayLabelDialog(false)
  }

  function setScrollLeft(scroll: number) {
    // Must be done after repaint and new scale is applied, so that scroll value
    // can be applied, taking new size into account
    timeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft = scroll
      }
    }, 0)
  }

  function scaleBy(coef: number) {
    if (!containerRef.current || !scrollRef.current) return

    const newScale = Math.max(1, Math.min(20, scale * coef))

    const containerWidth = containerRef.current.getBoundingClientRect().width

    const scrollLeft = scrollRef.current.scrollLeft
    const headPos = scale * containerWidth * (percent / 100) - scrollLeft

    if (headPos >= 0 && headPos <= containerWidth) {
      // Keep head pos at the same position
      setScrollLeft(
        scrollLeft + (newScale - scale) * (percent / 100) * containerWidth,
      )
    } else {
      // Zoom on middle pixel
      const middlePixel = scrollLeft + containerWidth / 2
      setScrollLeft((newScale / scale) * middlePixel - containerWidth / 2)
    }

    setScale(newScale)
  }

  if (isNaN(start) || isNaN(end))
    return (
      <div className="flex h-36 flex-col items-center justify-center bg-pink-600">
        Données invalides
      </div>
    )

  return (
    <div className="relative z-10 flex flex-col gap-2 px-4 py-2 shadow-lg shadow-gray-900">
      <div className="flex flex-row flex-wrap justify-center gap-2">
        {Object.entries(defaultMetrics).map(([metricKey, metricConfig]) => (
          <div key={metricKey} className="flex flex-col text-white">
            <span>{metricConfig.label}</span>
            <div
              className="h-1 w-full"
              style={{
                backgroundColor: metricConfig.color,
              }}
            />
          </div>
        ))}
      </div>
      <div>
        {data.map((soundData) => (
          <audio
            crossOrigin="anonymous"
            key={soundData.soundKey}
            src={soundURLs.current[soundData.soundKey]}
            ref={audioRefs.current[soundData.soundKey]}
          />
        ))}
      </div>
      <div className="flex flex-col gap-2" ref={containerRef}>
        <div className="flex flex-row justify-between">
          <button
            className="rounded-md bg-blue-500 px-2 pt-0.5 hover:bg-blue-700 disabled:opacity-30"
            disabled={end - start >= MAX_DURATION}
            onClick={handleIncreaseDuration}
            title="Voir les sons précédents"
          >
            ⬅
          </button>
          <div className="flex flex-row items-baseline gap-3">
            <div
              className="cursor-pointer select-none rounded-md bg-blue-500 px-2 font-semibold hover:bg-blue-700"
              onClick={handlePlaybackRateClick}
              title="Vitesse de lecture"
            >
              {playbackRate}x
            </div>
            <div
              className="flex w-7 cursor-pointer select-none flex-col items-center justify-center rounded-full bg-blue-500 p-2 hover:bg-blue-700"
              onMouseUp={togglePlayPause}
              title="Lecture / pause"
            >
              <svg viewBox="0 0 60 60" fill="#ffffff">
                {isPlaying ? (
                  <>
                    <polygon points="10,5 25,5 25,55 10,55" />
                    <polygon points="35,5 50,5 50,55 35,55" />
                  </>
                ) : (
                  <polygon points="10,0 60,30 10,60" />
                )}
              </svg>
            </div>
            <div className="tabular-nums">
              {formatTime(start + ((end - start) * percent) / 100, true)}
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <div
              className="flex h-7 w-7 cursor-pointer select-none flex-col items-center justify-center rounded-full bg-blue-500 text-xl font-semibold hover:bg-blue-700"
              onClick={() => scaleBy(0.8)}
              title="Dézoomer"
            >
              －
            </div>
            <div
              className="flex h-7 w-7 cursor-pointer select-none flex-col items-center justify-center rounded-full bg-blue-500 text-xl font-semibold hover:bg-blue-700"
              onClick={() => scaleBy(1.2)}
              title="Zoomer"
            >
              ＋
            </div>
          </div>
          <input
            type="range"
            value={contrast}
            onChange={(e) => setContrast(parseInt(e.target.value))}
            title="Réglage de la sensibilité"
          />
          <button
            className="rounded-full bg-violet-500 px-1.5 pt-0.5 hover:bg-violet-700 disabled:opacity-30"
            disabled={currentAudioElement === null}
            onClick={() => {
              currentAudioElement?.pause()
              setDisplayLabelDialog((value) => !value)
            }}
            title="Labeliser ce son"
          >
            🏷
          </button>
          <div title="Index dans la séquence">
            {sequenceIndex + 1}/{instants.length}
          </div>
        </div>
        <div className="relative">
          <div className="overflow-x-auto" ref={scrollRef}>
            <div className="flex flex-col" style={{ width: `${scale * 100}%` }}>
              <div
                className="relative h-40 bg-black bg-opacity-20"
                ref={trackRef}
                onClick={handleTrackClick}
              >
                {Object.entries(prefixDataMap).map(([prefix, data]) => {
                  if (!data) {
                    const left = DateTime.fromISO(prefix).valueOf()
                    const right = Math.min(left + BUCKET_DURATION, end)
                    return (
                      <div
                        key={prefix}
                        className="absolute inset-y-0 bg-slate-400 bg-opacity-30"
                        style={verticalBlockStyle(
                          left,
                          right,
                          start,
                          end - start,
                        )}
                      />
                    )
                  } else {
                    return data.map((soundData) => {
                      const ts = soundData.endTimestamp
                      const left = Math.max(ts - SOUND_DURATION, start)
                      const right = Math.min(ts, end)
                      if (right < left) return null
                      return (
                        <Predictions
                          key={soundData.soundKey}
                          left={left}
                          right={right}
                          start={start}
                          end={end}
                          contrast={contrast / 100}
                          text={soundTexts[soundData.soundKey]}
                          predictions={predictions[ts]}
                          metricsConfig={defaultMetrics}
                        />
                      )
                    })
                  }
                })}
                {instantsTs.slice(0, sequenceIndex).map((instant) => (
                  <div
                    key={instant}
                    className="absolute inset-y-0 -ml-0.5 w-0.5 bg-rose-600 bg-opacity-50"
                    style={{
                      left: `${((instant - start) / (end - start)) * 100}%`,
                    }}
                    title="Instant précédemment labellisé"
                  />
                ))}
                <div
                  className="absolute inset-y-0 -ml-0.5 w-0.5 bg-rose-600"
                  style={{
                    left: `${((end - start) / (end - start)) * 100}%`,
                  }}
                  title="Instant à labelliser"
                />
                {refInstant <= instantsTs[sequenceIndex] && (
                  <div
                    className={`absolute inset-y-0 -ml-1 w-0.5 bg-amber-400`}
                    style={{
                      left: `${((refInstant - start) / (end - start)) * 100}%`,
                    }}
                    title="Instant de référence"
                  />
                )}
                <div
                  className="absolute inset-y-0 z-10 -ml-0.5 w-0.5 bg-blue-500"
                  style={{ left: `${percent}%` }}
                />
              </div>
              <TimeMarks start={start} end={end} scale={scale} />
            </div>
          </div>
          <div className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center">
            {currentSoundKey && soundTexts[currentSoundKey] && (
              <div className="rounded-md bg-slate-500 bg-opacity-50 px-4 py-1 italic">
                {`“${soundTexts[currentSoundKey]}”`}
              </div>
            )}
            {Object.values(prefixDataMap).some(
              (prefixData) => prefixData === undefined || prefixData === null,
            ) ? (
              <div className="rounded-md bg-slate-500 bg-opacity-50 px-4 py-1">
                Chargement...
              </div>
            ) : data.length === 0 ? (
              <div className="rounded-md bg-slate-500 bg-opacity-50 px-4 py-1">
                Aucun son sur l'intervalle selectionné
              </div>
            ) : null}
          </div>
          {displayLabelDialog && currentSoundKey && (
            <LabelsDialog
              currentSoundKey={currentSoundKey}
              closePanel={closePanel}
            />
          )}
        </div>
        <div className="flex flex-row gap-2 ">
          <Button
            className={`flex gap-2 !rounded-full  ${
              autoVolumeAdjust ? '' : '!bg-red-600'
            }`}
            onClick={() =>
              autoVolumeAdjust
                ? setAutoVolumeAdjust(false)
                : setAutoVolumeAdjust(true)
            }
          >
            <span className="text-2xl" role="img" aria-label="Volume">
              {volume > 0.66 ? '🔊' : volume > 0.33 ? '🔉' : '🔈'}
            </span>
            <span className="self-center">
              {autoVolumeAdjust ? 'Auto' : 'Manuel'}
            </span>
          </Button>
          <input
            className={`flex-1 ${
              volume - admissibleVolume > 0.2
                ? 'accent-red-600'
                : volume - admissibleVolume > 0
                  ? 'accent-yellow-300'
                  : ''
            }`}
            type="range"
            value={volume}
            min={MIN_VOL}
            max={MAX_VOL}
            step={0.01}
            onChange={(e) => setVolume(parseFloat(e.target.value))}
            title="Réglage du volume"
          />
        </div>
      </div>
    </div>
  )
}
